@charset "UTF-8";


// ==================================================
//  @use
//  mixin: @include index.sp {~}
//  color: color.$write-main
// ==================================================
@use "mixin" as mixin;
@use "color" as color;


// ==================================================
//  Page Top（index.ejs）
// ==================================================
.page-top {
  .keyvisual {
    display: block;
    width: 100%;
    height: 700px;
    position: relative;

    @include mixin.sp {
      height: 290px;
    }

    &::after {
      content: "";
      width: calc(100% + 2px);
      height: 465px;
      background-image: url(../img/top/bg_visual.svg);
      background-size: auto 100%;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      z-index: 2;
      left: calc(50% - 1px);
      bottom: 0;
      transform: translateX(-50%);

      @include mixin.sp {
        height: 186px;
      }
    }

    &_image {
      display: block;
      width: 100%;
      max-width: 600px;
      position: absolute;
      z-index: 3;
      bottom: 200px;
      left: calc(50% - 195px);
      transform: translateX(-50%);

      @include mixin.sp {
        max-width: 200px;
        left: calc(50% - 78px);
        bottom: 100px;
      }
    }

    &_text {
      display: block;
      width: 100%;
      max-width: 337px;
      position: absolute;
      z-index: 3;
      bottom: 346px;
      left: calc(50% + 255px);
      transform: translateX(-50%);

      @include mixin.sp {
        max-width: 160px;
        bottom: 130px;
        left: calc(50% + 85px);
      }
    }
  }

  .mission {
    padding: 63px 0 140px;
    color: color.$write-white;
    background-color: color.$site-main;
    overflow: hidden;

    @include mixin.sp {
      padding: 60px 0 100px;
    }

    .pageTitleImage {
      height: 41px;
      margin-bottom: 84px;

      @include mixin.sp {
        height: 30px;
        margin-bottom: 0;
      }
    }

    &_content {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: top;
      width: 100%;
      max-width: 940px;
      margin: 65px auto 0;

      @include mixin.sp {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        max-width: calc(100% - 40px);
        margin: 45px auto 0;
      }

      // Modifier
      &-1 {
        .mission_content_text {
          @include mixin.pc {
            width: calc(100% - (369px + 25px));
          }

          &_title {
            width: 334px;

            @include mixin.sp {
              width: 210px;
            }
          }
        }

        .mission_content_image {
          @include mixin.pc {
            width: 369px;
          }

          @include mixin.sp {
            margin-top: 20px;
          }

          & > img {
            @include mixin.pc {
              left: -60px;
            }
          }
        }
      }
      &-2 {
        flex-direction: row-reverse;

        .mission_content_text {
          @include mixin.pc {
            width: calc(100% - (430px + 99px));
          }

          &_title {
            width: 296px;

            @include mixin.sp {
              width: 185px;
            }
          }
        }

        .mission_content_image {
          @include mixin.pc {
            width: 430px;
          }

          @include mixin.sp {
            max-width: calc(100% - 40px);
            margin: 40px auto 0;
            transform: translateX(-4px);
          }

          & > img {
            @include mixin.pc {
              right: 0;
            }
          }
        }
      }

      &_text {
        display: block;
        padding-top: 30px;

        @include mixin.sp {
          width: 100%;
          padding-top: 0;
        }

        &_title {
          display: block;
          margin: 0 0 70px;

          @include mixin.sp {
            margin-bottom: 30px;
          }

          & > img {
            display: block;
            width: 100%;
          }
        }

        &_paragraph {
          margin: 27px 0 0;
          font-size: 1.8rem;
          line-height: 1.77;
          letter-spacing: 0.045em;

          @include mixin.sp {
            margin-top: 20px;
            font-size: 1.5rem;
            line-height: 1.66;
          }
        }
      }

      &_image {
        display: block;
        position: relative;

        & > img {
          display: block;

          @include mixin.pc {
            width: 660px;
            max-width: none;
            position: absolute;
            top: 0;
          }

          @include mixin.sp {
            width: 100%;
          }
        }
      }
    }
  }

  .movie {
    /* File: style-bg-youtube.scss */
  }

  .service {
    display: block;
    margin-top: 8px;
    padding: 105px 0 42px;
    overflow: hidden;

    @include mixin.sp {
      margin-top: 0;
      padding: 60px 0 20px;
    }

    &_slider {
      display: block;
      width: 100%;
      max-width: 860px;
      height: auto;
      margin: 43px auto 0;

      @include mixin.sp {
        max-width: calc(100% - 40px);
        margin-top: 30px;
      }

      img {
        display: block;
      }

      // Slick Slider
      &:not(.slick-initialized) {
        display: none;
      }
      &.slick-slider {
        .slick-prev {
          width: 38px;
          height: 43px;
          left: -56px;

          @include mixin.sp {
            display: none;
          }

          &::before {
            content: "";
            display: block;
            background: none;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 21.5px 38px 21.5px 0;
            border-color: transparent color.$site-main transparent transparent;
            opacity: 0.13;
          }
        }
        .slick-next {
          width: 38px;
          height: 43px;
          right: -56px;
          transform: translate(0, -50%) rotate(180deg);

          @include mixin.sp {
            display: none;
          }

          &::before {
            content: "";
            display: block;
            background: none;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 21.5px 38px 21.5px 0;
            border-color: transparent color.$site-main transparent transparent;
            opacity: 0.13;
          }
        }
      }
    }

    &_sliderThumbnail {
      display: block;
      width: 1400px;
      height: auto;
      margin-top: 37px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);

      @include mixin.sp {
        max-width: 450px;
        margin-top: 20px;
      }

      img {
        display: block;
      }

      // Slick Slider
      &:not(.slick-initialized) {
        display: none;
      }
      &.slick-slider {
        .slick-slide {
          margin: 0 10.5px;

          @include mixin.sp {
            margin: 0 4px;
          }
        }
      }
    }
  }

  .company {
    display: block;
    padding: 105px 0;

    @include mixin.sp {
      padding: 60px 0 20px;
    }

    &_profile {
      width: 100%;
      max-width: 860px;
      margin: 59px auto 0;
      padding: 0;
      line-height: 1.4;

      @include mixin.sp {
        max-width: calc(100% - 40px);
        margin-top: 30px;
      }

      &_inner {
        display: flex;
        column-gap: 28px;

        @include mixin.sp {
          column-gap: 15px;
        }
      }

      &_title {
        width: 173px;
        text-align: center;
        padding: 17px 15px 15px;
        border-bottom: 1px solid color.$border-black;

        @include mixin.sp {
          width: 100px;
          padding: 14px 8px;
        }
      }

      &_content {
        width: calc(100% - (173px + 28px));
        margin: 0;
        padding: 17px 30px 15px;
        border-bottom: 1px solid color.$border-black;

        @include mixin.sp {
          width: calc(100% - (100px + 15px));
          padding: 14px 10px;
        }

        & > :first-child {
          margin-top: 0;
        }

        a {
          text-decoration: none;
        }

        &_list {
          display: block;
          padding-left: 1.3em;
          text-indent: -1.3em;
          margin-top: 6px;

          &::before {
            content: "○ ";
          }
        }
      }
    }
  }

  .access {
    display: block;
    padding: 105px 0 0;

    @include mixin.sp {
      padding: 60px 0 20px;
    }

    .pageParagraph {
      @include mixin.pc {
        margin-top: 35px;
      }
    }

    &_googleMap {
      display: block;
      width: 100%;
      position: relative;
      min-height: 535px;
      padding-top: 38.2142%;
      margin-top: 56px;

      @include mixin.sp {
        min-height: 280px;
        margin-top: 30px;
      }

      & > iframe {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}